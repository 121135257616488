
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import TahsilatList from "@/components/lists/TahsilatList.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import AlacakKalemiDetayForm from "@/components/takip/form/AlacakKalemiDetayForm.vue";
import KapakHesabiAlacakKalemleriInfo from "@/components/infos/KapakHesabiAlacakKalemleriInfo.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {AlacakKalemiAlani, AlacakKalemiAlaniLabel} from "@/enum/AlacakKalemiAlani";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import KapakHesabi from "@/classes/evrak/KapakHesabi/KapakHesabi";
import KapakHesabiCalculator from "@/classes/evrak/KapakHesabi/KapakHesabiCalculator";
import {TahsilatEntity} from "@/entity/TahsilatEntity";
import OnizleForm from "@/components/forms/OnizleForm.vue";
import {TakipDurumu} from "@/enum/TakipDurumu";
import MasrafHarcForm from "@/components/forms/MasrafHarcForm.vue";
import IhtiyatiHacizAlacakKalemiForm from "@/components/forms/alacak-kalemi/IhtiyatiHacizAlacakKalemiForm.vue";
import {TakipAltTipHesapAyarEntity} from "@/entity/TakipAltTipHesapAyarEntity";
import {TakipYoluLabel} from "@/enum/TakipYolu";
import FormWrapper from "@/components/FormWrapper.vue";
import Dates from "@/components/inputs/Dates.vue";
import HesapTarihiForm from "@/components/forms/HesapTarihiForm.vue";
import HarcAlacakKalemleriInfo from "@/components/infos/HarcAlacakKalemleriInfo.vue";
import BorcluForm from "@/components/forms/BorcluForm.vue";
import TakipAlacakliPicker from "@/components/pickers/TakipAlacakliPicker.vue";
import AlacakList from "@/components/lists/AlacakList.vue";
import DokumanList from "@/components/lists/DokumanList.vue";
import {TahsilatTuru} from "../../enum/TahsilatTuru";
import {helpers} from "vuelidate/lib/validators";
interface HataMesaji {
  key: string;
  mesaj: any;
}
@Component({
  computed: {
    TahsilatTuru() {
      return TahsilatTuru
    }
  },
  components: {
    DokumanList,
    AlacakList,
    TakipAlacakliPicker,
    BorcluForm,
    HarcAlacakKalemleriInfo,
    HesapTarihiForm,
    Dates,
    FormWrapper,
    IhtiyatiHacizAlacakKalemiForm,
    MasrafHarcForm,
    OnizleForm,
    FormDialog,
    KapakHesabiAlacakKalemleriInfo,
    AlacakKalemiDetayForm,
    TahsilatList,
  },
})
export default class KapakHesabiInfo extends Vue {

  @Prop() takip!: TakipEntity;
  @Prop({default: false}) IsHesapTarihi!: boolean;
  kapakHesabi: KapakHesabi = new KapakHesabi();
  tab = null;
  items = [{tab: 'Kapak Hesabı', content: 'Tab 1 Content'}];

  TakipDurumu = TakipDurumu;
  ParaBirimi = ParaBirimi;
  AlacakKalemiAlaniLabel = AlacakKalemiAlaniLabel;
  AlacakKalemiAlani = AlacakKalemiAlani;
  TakipYoluLabel = TakipYoluLabel;
  show = false;
  xmlUyari = false;
  hataMesajlari:HataMesaji[] = [];
  takipAc = false

  get tahsilatTutari() {
    let toplam = 0;
    this.takip?.tahsilatlar.forEach((tahsilat: TahsilatEntity) => {
      if (tahsilat.para_birimi_id == this.ParaBirimi.TRY)
        toplam += Number(tahsilat.tutar);
      else {
        toplam += Number(tahsilat.tutar) * tahsilat.kur
      }
    })
    return toplam;
  }

  onlineXmlDownload(){
    this.xmlDownload(this.takip.id,'online')
    this.takipAc = false
  }

  manuelXmlDownload(){
    this.xmlDownload(this.takip.id,'manuel')
    this.takipAc = false
  }

  mounted() {
    if (!this.kapakHesabi) {
      this.kapakHesabi = new KapakHesabi();
    }
    this.calculateKapakHesabi();

  }

  onizle() {
    this.calculateKapakHesabi();
  }

  dortteUcMu() {
    if (this.$route.params.id != null) {
      this.$http
          .put('/api/v1/takip/' + parseInt(this.$route.params.id), this.takip)
          .then((response: any) => {
            this.takip = response.data;
            this.load();
          });
    }
  }

  get alacakKalemiAlanlari() {
    let alacakKalemiAlanlari: Array<any> = [];
    this.kapakHesabi?.alacakKalemiAlanlari.forEach((alacakKalemiAlaniId) => {
      this.takip.talepler[0].alt_tip_hesap_ayar.forEach((item) => {
        if (item.alacak_kalemi_alani_id === alacakKalemiAlaniId) {
          if (item.oran) {
            if (item.etiket){
              let alan = {id: item.alacak_kalemi_alani_id, ad: item.etiket+ (item.oran!=0 ? ` (% ${item.oran})` : '')}
              alacakKalemiAlanlari.push(alan)
            }else{
              let alan = {id: item.alacak_kalemi_alani_id, ad: item.alacak_kalemi_alani.isim+ (item.oran!=0 ? ` (% ${item.oran})` : '')}
              alacakKalemiAlanlari.push(alan)
            }

          } else {
            if (item.etiket){
              let alan = {id: item.alacak_kalemi_alani_id, ad: item.etiket}
              alacakKalemiAlanlari.push(alan)
            }else{
              let alan = {id: item.alacak_kalemi_alani_id, ad: AlacakKalemiAlaniLabel[alacakKalemiAlaniId]}
              alacakKalemiAlanlari.push(alan)
            }

          }
        }
      })
    })
    return alacakKalemiAlanlari
  }

  get takipSonrasiAlacakKalemiAlanlari() {
    let takipSonrasialacakKalemiAlanlari: Array<any> = [];
    this.kapakHesabi.takipSonrasialacakKalemiAlanlari.forEach((takipSonrasiAlacakKalemiAlaniId) => {
      this.takip.talepler[0].alt_tip_hesap_ayar.forEach((item) => {
        if (item.alacak_kalemi_alani_id === takipSonrasiAlacakKalemiAlaniId) {
          if (item.etiket) {
            if (item.oran){
              let takipSonrasiAlan = {id: item.alacak_kalemi_alani_id, ad: item.etiket+ (item.oran!=0 ? ` (% ${item.oran})` : '')}
              takipSonrasialacakKalemiAlanlari.push(takipSonrasiAlan)
            }else{
              let takipSonrasiAlan = {id: item.alacak_kalemi_alani_id, ad: item.etiket}
              takipSonrasialacakKalemiAlanlari.push(takipSonrasiAlan)
            }
          } else {
            if (item.oran){
              let takipSonrasiAlan = {id: item.alacak_kalemi_alani_id, ad: item.alacak_kalemi_alani.isim+ (item.oran!=0 ? ` (% ${item.oran})` : '')}
              takipSonrasialacakKalemiAlanlari.push(takipSonrasiAlan)
            }else{
              let takipSonrasiAlan = {id: item.alacak_kalemi_alani_id, ad: AlacakKalemiAlaniLabel[takipSonrasiAlacakKalemiAlaniId]}
              takipSonrasialacakKalemiAlanlari.push(takipSonrasiAlan)
            }

          }
        }
      })
    })
    return takipSonrasialacakKalemiAlanlari
  }

  get alacakKalemleri() {
    if (this.takip && this.takip.talepler[0] && this.takip.talepler[0].alacaklar && this.takip.talepler[0].alacaklar.length > 0) {
      if (this.takip.talepler[0] && this.takip.talepler[0].alacaklar && this.takip.talepler[0].alacaklar[0].asil_alacaklar && this.takip.talepler[0].alacaklar[0].asil_alacaklar.length > 0) {
        return true;
      }
    } else {
      return false
    }
  }

  get checkVadeTarihliKur(){
    return this.takip.talepler[0].alacaklar.map(alacak => alacak.kur).some(kur => kur != null)
  }

  checkTahsilatTarihliKur(){
    return this.takip.talepler[0].alacaklar.map(alacak => alacak.doviz_kur_tarihi).some(kur => kur == 'tahsilat')
  }

  get alacakBelgeTuruId(): number | null {
    if (this.takip && this.takip.talepler[0]?.alacaklar) {
      return this.takip.talepler[0]?.alacaklar[0].alacak_belge_turu_id;
    }
    return null;
  }

  @Watch('takip')
  onTakipChange() {
    this.calculateKapakHesabi();
  }

  calculateKapakHesabi() {
    if (this.takip.id) {
      let kapakHesabiCalculator: KapakHesabiCalculator = new KapakHesabiCalculator();
      kapakHesabiCalculator.setTakip(this.takip);
      this.kapakHesabi = kapakHesabiCalculator.calculateKapakHesabi();
      this.kapakHesabi.alacakKalemiAlanlari.sort(function (a, b) {
        return a - b;
      });
      this.kapakHesabi.takipSonrasialacakKalemiAlanlari.sort(function (a, b) {
        return a - b;
      });
    }

  }

  load() {
    this.$emit('load')
  }


  get cezaEviHarci(){
    let tahsilatlar:any = []

    this.takip.tahsilatlar.forEach((tahsilat:any)=>{
      tahsilatlar ={"cezaevi_harci":tahsilat.cezaevi_harci,
        "tahsilat_turu": tahsilat.tahsilat_turu}
    })
    return tahsilatlar
  }
  xmlDownload(id: number, tip: string) {
    this.hataMesajlari = [];
    this.xmlUyari = false;
    this.show = true;
    this.$http
        .get('/api/v1/takip/' + parseInt(this.$route.params.id) + '/' + tip + 'xml')
        .then((response: any) => {
          var pom = document.createElement('a');
          var data = new Blob([response], {type: 'text/plain'});

          pom.setAttribute('href', window.URL.createObjectURL(data));
          pom.setAttribute('download', 'İcraProTakipXml-' + parseInt(this.$route.params.id) + '.xml');
          pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');

          pom.click();
          this.xmlUyari = false;
          this.show = false;
        }).catch((error) => {
      error.response.data.forEach((data: any) => {
        for (const [key, value] of Object.entries(data)) {
          this.hataMesajlari.push({key: key, mesaj: value});
        }
        this.xmlUyari = true;
      });
    });
  }

  uyapAktar() {
    if (this.takip.takip_tarihi && (new Date(this.takip.takip_tarihi).getTime() == new Date(new Date().toISOString().substr(0, 10)).getTime()))
      console.log('true')
    else
      this.$toast.warning('Takip tarihi bugün olmalıdır.');
  }
}
